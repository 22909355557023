import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ChatbotBubble = () => {
  const location = useLocation();

  useEffect(() => {
    const embedScriptConfig = document.getElementById('chatbot-config-script');
    const embedScript = document.getElementById('chatbot-main-script');

    if (!embedScriptConfig) {
      const configScript = document.createElement('script');
      configScript.id = 'chatbot-config-script';
      configScript.type = 'text/javascript';
      configScript.innerHTML = `
        window.embeddedChatbotConfig = {
          chatbotId: "${process.env.REACT_APP_CHATBOT_ID}",   
          domain: "www.chatbase.co"
        };
      `;  // "poI0MZvJr_z4bGQzlCMjh",
      document.body.appendChild(configScript);
    }

    if (!embedScript) {
      const mainScript = document.createElement('script');
      mainScript.id = 'chatbot-main-script';
      mainScript.src = "https://www.chatbase.co/embed.min.js";
      mainScript.defer = true;
      document.body.appendChild(mainScript);
    }

    // Definir visibilidade baseada na rota
    const chatbotStyle = document.createElement('style');
    chatbotStyle.id = 'chatbot-custom-style';
    document.head.appendChild(chatbotStyle);
    chatbotStyle.innerHTML = `
      .chatbot-container { display: ${location.pathname === '/main' ? 'block' : 'none'}; }
    `;

    return () => {
      // Não remover o script ou o estilo, apenas ajustar visibilidade
      chatbotStyle.innerHTML = `.chatbot-container { display: none; }`;
    };
  }, [location.pathname]);  // Dependência na mudança da rota

  return null;
};

export default ChatbotBubble;
