import './LoginForm.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
//import '@sweetalert2/theme-material-ui/material-ui.css';
import translations from '../utils/translations.json';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";

const LoginForm = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const isDesktop = window.innerWidth > 768;

    const location = useLocation();



    // Lista de idiomas suportados
    const supportedLanguages = ['pt', 'en']; // Adicione mais códigos de idioma conforme necessário
    const defaultLanguage = 'en'; // Define inglês como idioma padrão

    // Define o idioma com base no navegador ou retorna ao padrão se não suportado
    const browserLanguage = navigator.language.slice(0, 2);
    const initialLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : defaultLanguage;

    const [language, setLanguage] = useState(localStorage.getItem('language') || initialLanguage);

    // const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    //const [language, setLanguage] = useState(localStorage.getItem('language') || navigator.language.slice(0, 2));


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const langFromUrl = params.get('lang');
        if (langFromUrl && supportedLanguages.includes(langFromUrl) && langFromUrl !== language) {
            setLanguage(langFromUrl);
            localStorage.setItem('language', langFromUrl);
        }
    }, [location, language]);


    const t = (screen, key) => translations[language][screen][key];

    const handleSubmit = (e) => {
        e.preventDefault();

        const user = {
            email: email,
            password: password
        }

        axios.post(process.env.REACT_APP_API_BASE_URL + '/users/login', user)
            .then(async response => {

                localStorage.setItem('username', response.data.username);
                localStorage.setItem('credits', JSON.stringify(response.data.credits));
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('isAdmin', response.data.isAdmin);
                localStorage.setItem('language', response.data.language);
                localStorage.setItem('typeofPlan', response.data.typeofPlan);
                
                setLanguage(response.data.language);

                const firstAccess = response.data.firstAccess !== undefined ? response.data.firstAccess : false;

                const activeToten = response.data.activeToten;

                localStorage.setItem('name', activeToten.perfilName);
                localStorage.setItem('lastname', activeToten.perfilLastName);
                localStorage.setItem('perfil_picture', activeToten.profilePicture);
                localStorage.setItem('userHandle', activeToten.userHandle);
                localStorage.setItem('characteristic', activeToten.characteristic);
                localStorage.setItem('colors', JSON.stringify(activeToten.colors));
                localStorage.setItem('titleFont', activeToten.titleFont); // Salva a fonte do título
                localStorage.setItem('textFont', activeToten.textFont);
                localStorage.setItem('totenId', activeToten.toten);
                localStorage.setItem('tokenExpiry', activeToten.tokenExpiry);


                if (response.data.isAdmin && isDesktop) {
                    if (response.data.can_add_users) {
                        const result = await Swal.fire({
                            title: t('login', 'adminPagePrompt'),
                            text: t('login', 'adminPrompt'),
                            icon: 'question',
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: t('login', 'adminConfirm'),
                            denyButtonText: t('login', 'controlPanel'),
                            cancelButtonText: t('login', 'mainPage'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });

                        if (result.isConfirmed) {
                            window.location.href = `/register?lang=${language}`; /*+ response.data.userId;*/
                        } else if (result.isDenied) {
                            window.location.href = "/controlpanel/" + response.data.userId;
                        } else {
                            window.location.href = `/main?lang=${language}`;
                        }
                    } else {
                        const result = await Swal.fire({
                            title: t('login', 'adminPagePrompt'),
                            text: t('login', 'adminPrompt'),
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: t('login', 'yes'),
                            cancelButtonText: t('login', 'no'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                        if (result.isConfirmed) {
                            window.location.href = "/controlpanel/" + response.data.userId;
                        } else {
                            window.location.href = `/main?lang=${language}`;
                        }
                    }
                } else {
                    //window.location.href = `/main?lang=${language}`;

                    if (firstAccess) {
                        localStorage.setItem('firstAccess', firstAccess);
                        window.location.href = `/viewuser?lang=${language}`;
                    } else {
                        window.location.href = `/main?lang=${language}`;
                    }
                }
            })

            .catch(error => {
                //console.log(error);
                let message = t('login', 'internalServerError');
                if (error.response) {
                    // Erro vindo do servidor
                    if (error.response.status === 401) {
                        message = t('login', 'incorrectCredentials');
                    } else if (error.response.status === 404) {
                        message = t('login', 'userNotFound');
                    } // Adicione mais condições conforme necessário
                }

                Swal.fire({
                    title: 'Erro',
                    text: message,
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            });
    }

    return (

        <div className="login-form">
            <div className="logo-container">
                <img
                    src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                    alt="Logo"
                    className="logo-image"
                />
            </div>
            <form onSubmit={handleSubmit}>
                {/* Inserção do logo */}
                <label style={{ color: '#757575' }}>
                    {t('login', 'email')}
                    <input type="email" value={email} className='inputText' onChange={e => setEmail(e.target.value)} />
                </label>
                <label style={{ color: '#757575' }}>
                    {t('login', 'password')}
                    <input type="password" value={password} className='inputText' onChange={e => setPassword(e.target.value)} />
                </label>
                <input className="submit-button" type="submit" value={t('login', 'login')} />
                <h5 style={{ textAlign: "center", marginTop: "15px" }}>
                    <Link style={{ color: '#757575' }} to={`/resetpasswordrequest?lang=${language}`}>{t('login', 'forgotPassword')}</Link>
                </h5>
            </form>
        </div>
    );

}

export default LoginForm;
