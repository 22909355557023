import React, { useState, useEffect } from 'react';
import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerFooter, List, ListItem, ListIcon, Divider, RadioGroup, Radio, Stack, ChakraProvider, Box } from "@chakra-ui/react";
import { MdLogout, MdPerson, MdStyle, MdLocalActivity, MdTune, MdBrush, MdLanguage, MdFace } from "react-icons/md";
import "./CustomDrawer.css";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { FaPalette } from 'react-icons/fa';
import '@sweetalert2/theme-material-ui/material-ui.css';
import CryptoJS from 'crypto-js';

const CustomDrawer = ({ isOpen, onClose, handleChange, isGPT4Enabled, onContentOpen, onAnalysisOpen, onHistoryOpen, onPerfilOpen, onPCI, onLogout, language, setLanguage, t, isCanvaConnected }) => {


    const [canvasConnected, setCanvasConnected] = useState(false);

    const clientId = process.env.REACT_APP_CANVA_CLIENT_ID;
    const scopes = process.env.REACT_APP_CANVA_SCOPES;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        setCanvasConnected(isCanvaConnected); // Diretamente sincronizado
    }, [isCanvaConnected]);


    const handleCanvasConnect = async () => {

        const code_verifier = CryptoJS.lib.WordArray.random(96).toString(CryptoJS.enc.Base64url);
        const code_challenge = CryptoJS.SHA256(code_verifier).toString(CryptoJS.enc.Base64url);

        const userId = localStorage.getItem('userId');
        const language = localStorage.getItem('language');

        if (isCanvaConnected) {
            onClose();
            Swal.fire({
                title: t('drawer', 'disconnectTitle'),
                text: t('drawer', 'disconnectPrompt'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('drawer', 'yes'),
                cancelButtonText: t('drawer', 'cancel'),
                reverseButtons: true,
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }

            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/canva-delete-session`, {
                            userId: userId
                        });
                        if (response.data.success) {
                            setCanvasConnected(false);
                            localStorage.setItem('accessToken', false);
                            Swal.fire({
                                title: t('drawer', 'disconnectedTitle'),
                                text: t('drawer', 'successfulDisconnection'),
                                icon: 'success',
                                customClass: {
                                    popup: 'my-swal-alert',
                                    title: 'myCustomTitle'
                                }
                            });
                        } else {
                            throw new Error('Falha ao desconectar');
                        }
                    } catch (error) {
                        console.error('Erro ao desconectar do Canva:', error);
                        Swal.fire({
                            title: t('drawer', 'error'),
                            text: t('drawer', 'errorDisconnectingCanva'),
                            icon: 'error',
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                    }
                }
            });

        } else {

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/store-session`, {
                userId: userId,
                codeVerifier: code_verifier
            });

            const sessionId = userId + ':' + language;

            const redirectUri = `${process.env.REACT_APP_URL_REDIRECT}/logoscreen`;


            const authorizationUrl = `https://www.canva.com/api/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}&code_challenge=${code_challenge}&code_challenge_method=S256&state=${encodeURIComponent(sessionId)}`;


            if (isMobile) {
                window.location.href = authorizationUrl;
            } else {
                const width = 600;
                const height = 600;
                const left = (window.innerWidth / 2) - (width / 2);
                const top = (window.innerHeight / 2) - (height / 2);
                window.open(authorizationUrl, 'CanvaAuth', `width=${width},height=${height},top=${top},left=${left}`);
            }
            onClose();
        }
    };

    // Recupera o objeto de créditos do localStorage
    const getCredits = () => {
        const creditsString = localStorage.getItem('credits');
        return creditsString ? JSON.parse(creditsString) : { "GPT-3": 0, "GPT-4": 0 };
    };

    const handleLanguageChange = (value) => {
        setLanguage(value);
        localStorage.setItem('language', value);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/update`, {
            userId: localStorage.getItem('userId'),
            language: value
        }).then(response => {
            onClose();
        }).catch(error => {
            console.error(error);
            Swal.fire({
                title: t('drawer', 'error'),
                text: t('drawer', 'unknownLanguageChangeError'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        });
    };


    // Armazena os créditos GPT-3 e GPT-4 em variáveis
    const credits = getCredits();
    const creditsGPT3 = credits["GPT-3"] || 0;
    const creditsGPT4 = credits["GPT-4"] || 0;

    // ... restante do código ...


    //  const [writingStyle, setWritingStyle] = useState("");
    const userId = localStorage.getItem('userId');
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    const typeofPlan = localStorage.getItem('typeofPlan');
    const isDesktop = window.innerWidth > 768;

    const onControlPanelOpen = () => {
        window.location.href = `/controlpanel/${userId}`;
    }


    function displayCreditPurchaseOptions() {
        Swal.fire({
            title: t('drawer', 'buyCredits'),
            input: 'radio',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            inputOptions: {
                '50': t('drawer', '50'),
                '100': t('drawer', '100'),
                '200': t('drawer', '200')
            },
            inputValidator: (value) => {
                if (!value) {
                    return t('drawer', 'chooseOption');
                }
            },
            confirmButtonText: 'Comprar'
        }).then((result) => {
            if (result.isConfirmed) {
                redirectToEduzzCheckout(result.value);
            }
        });
    }

    function redirectToEduzzCheckout(creditAmount) {
        // Redireciona o usuário para a página de checkout da Eduzz com a quantidade de créditos escolhida
        // Você precisará da URL de checkout da Eduzz para cada pacote de créditos
        const checkoutUrls = {
            '50': 'https://sun.eduzz.com/2230353',
            '100': 'https://sun.eduzz.com/2230366',
            '200': 'https://sun.eduzz.com/2230371'
        };

        //  window.location.href = checkoutUrls[creditAmount];
        window.open(checkoutUrls[creditAmount], '_blank');

    }

    /* function isNotProductionEnvironment() {
        return !window.location.href.includes('app.postsucesso.com.br');
    } */


    const closeDrawerAndShowPopup = async () => {
        onClose(); // Fecha o Drawer
        setTimeout(() => displayCreditPurchaseOptions(), 500); // Espera um pouco e mostra o popup
    }

    return (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', opacity: 0.7 }} />
            <DrawerContent>
                <DrawerHeader style={{ marginBottom: '90px' }}>
                    <div className="image-container-drawer">
                        <img src={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} alt="" className="slide-image-drawer" />
                    </div>
                </DrawerHeader>
                <DrawerBody>
                    <List spacing={4}>
                        <Divider />
                        <ListItem onClick={onContentOpen} style={{ cursor: 'pointer' }} display="flex" alignItems="center">
                            <ListIcon as={MdStyle} color="#757575" className='list-item-icon' />
                            {t('drawer', 'contentCreation')}
                        </ListItem>
                        {/* <ListItem onClick={onAnalysisOpen} style={{ cursor: 'pointer' }} display="flex" alignItems="center">
                            <ListIcon as={MdScience} color="#757575" className='list-item-icon' />
                            {t('drawer', 'postAnalysis')}
                        </ListItem>  */}
                        <ListItem onClick={onPerfilOpen} style={{ cursor: 'pointer' }} display="flex" alignItems="center" marginBottom="20px">
                            <ListIcon as={MdPerson} color="#757575" className='list-item-icon' />
                            {t('drawer', 'profiles')}
                        </ListItem>
                        {typeofPlan !== "Starter" && (
                            <ListItem onClick={onPCI} style={{ cursor: 'pointer' }} display="flex" alignItems="center" marginBottom="20px">
                                <ListIcon as={MdFace} color="#757575" className='list-item-icon' />
                                {t('drawer', 'pci')}
                            </ListItem>
                        )}
                        <ListItem onClick={onHistoryOpen} style={{ cursor: 'pointer' }} display="flex" alignItems="center">
                            <ListIcon as={MdBrush} color="#757575" className='list-item-icon' />
                            {t('drawer', 'creationHistory')}
                        </ListItem>
                    </List>
                    <List spacing={4}>
                        <p></p>
                        <Divider />

                        {isAdmin && isDesktop && (
                            <ListItem onClick={onControlPanelOpen} style={{ cursor: 'pointer' }} display="flex" alignItems="center">
                                <ListIcon as={MdTune} color="#757575" className='list-item-icon' />
                                {t('drawer', 'controlPanel')}
                            </ListItem>
                        )}

                        <ListItem onClick={closeDrawerAndShowPopup} style={{ cursor: 'pointer' }} display="flex" alignItems="center">
                            <ListIcon as={MdLocalActivity} color="#757575" className='list-item-icon' />
                            {t('drawer', 'gpt4Credits')} {creditsGPT4}
                        </ListItem>
                        {typeofPlan !== "Starter" && (
                            <ListItem>
                                <ChakraProvider>
                                    <RadioGroup onChange={handleLanguageChange} value={language}>
                                        <Stack direction="row" spacing={0} alignItems="center">
                                            <ListIcon as={MdLanguage} color="#757575" className='list-item-icon' />
                                            <Box pr={4}>
                                                <Radio colorScheme='purple' value='pt'>PT</Radio>
                                            </Box>
                                            <Radio colorScheme='purple' value='en'>EN</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </ChakraProvider>
                            </ListItem>
                        )}
                        <ListItem onClick={handleCanvasConnect} style={{ cursor: 'pointer' }} display="flex" alignItems="center">
                            {/* <ListIcon as={FaPalette} color="#757575" className='list-item-icon' /> */}
                            <img src="https://www.canva.dev/assets/connect/1.svg" alt="Canva Icon" style={{ width: isMobile ? '30px' : '35px', height: isMobile ? '30px' : '35px', marginRight: isMobile ? '8px' : '10px' }} />
                            {t('drawer', canvasConnected ? 'canvasOn' : 'canvasOff')}
                        </ListItem>

                    </List>
                </DrawerBody>
                <DrawerFooter>
                    <List style={{ width: '100%' }} >
                        <Divider />
                        <ListItem onClick={onLogout} style={{ cursor: 'pointer', marginTop: '10px' }} display="flex" alignItems="center">
                            <ListIcon as={MdLogout} color="#757575" className='list-item-icon' />
                            {t('drawer', 'exit')}
                        </ListItem>
                        <ListItem style={{ marginTop: '10px', fontSize: '12px' }}>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <Link to={`/privacypolicy?lang=${language}`} style={{ color: '#757575', textDecoration: 'underline' }}>
                                    {t('drawer', 'privacyPolicy')}
                                </Link>
                                <Link to={`/termsservice?lang=${language}`} style={{ color: '#757575', textDecoration: 'underline' }}>
                                    {t('drawer', 'termsService')}
                                </Link>
                            </div>
                        </ListItem>
                    </List>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};


export default CustomDrawer;