import React, { useState, useEffect } from 'react';
import { MdMenu } from "react-icons/md";
import { ChakraProvider } from "@chakra-ui/react";
import CustomDrawer from './CustomDrawer';
import Swal from 'sweetalert2';
import translations from '../utils/translations.json';
import { useNavigate } from 'react-router-dom';
import './HistoryScreen.css'; // Importando o arquivo de estilos CSS
import { useLocation } from 'react-router-dom';

const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }
    return true;
};

const UserProfileImage = ({ userId, perfilPicture }) => {
    const defaultAvatarUrl = `https://example.com/avatar/${userId}`;
    const imageUrl = isValidUrl(perfilPicture) ? perfilPicture : defaultAvatarUrl;
    return (
        <div className="user-profile-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
    );
};

const UserInfo = ({ userName, userId, userCredits }) => {
    const handleClick = () => {
        window.open('/viewuser', "_blank");
    };
    return (
        <div className="user-info" onClick={handleClick}>
            <UserProfileImage userId={userId} perfilPicture={localStorage.getItem('perfil_picture')} />
            <div className="user-credits">
                {userName} | {userCredits} {t('history', 'credits')}
            </div>
            <div className="userInfoMobile">
                {userCredits} {t('history', 'credits')}
            </div>
        </div>
    );
};


let prevPage = 1;

let t;

const HistoryScreen = () => {

    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event) => {
        const isGPT4Enabled = event.target.checked;
        setIsGPT4Enabled(isGPT4Enabled);
        localStorage.setItem('isGPT4Enabled', isGPT4Enabled.toString());
    };

    const location = useLocation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');

   /* useEffect(() => {
        const params = new URLSearchParams(location.search);
        const langFromUrl = params.get('lang');
        if (langFromUrl && langFromUrl !== language) {
            setLanguage(langFromUrl);
            localStorage.setItem('language', langFromUrl);
        }
    }, [location, language]);  */

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'language') {
                setLanguage(localStorage.getItem('language') || 'pt');
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    t = (screen, key) => translations[language][screen][key];


    const handleHamburguerClick = () => {
        setIsOpen(true); // Abre o Drawer
    };

    const handleCloseDrawer = () => {
        setIsOpen(false); // Fecha o Drawer
    };

    const mainOpen = (language) => {
        navigate(`/main?lang=${language}`);
    }

    const analysisOpen = () => {
        navigate(`/analysis?lang=${language}`);
    }

    const perfilOpen = () => {
        navigate(`/viewuser?lang=${language}`);
    }

    const pciOpen = (language) => {
        navigate(`/pci?lang=${language}`);
    }

    const logout = (language) => {
        navigate(`/login?lang=${language}`);
    }

    useEffect(() => {
        return () => {
            // Resetar o estado aqui
            setHistoryData([]);
            setIsLoadingInitialData(true);
            prevPage = 1;
        };
    }, []);

    const [isGPT4Enabled, setIsGPT4Enabled] = useState(() => {
        const saved = localStorage.getItem('isGPT4Enabled');
        return saved === 'true'; // Retorna true se o valor armazenado for a string 'true'
    });

   

    const userName = localStorage.getItem('username');
    const userId = localStorage.getItem('userId');
   // let bool = true;

    const getCreditsSum = () => {
        const creditsString = localStorage.getItem('credits');
        const credits = creditsString ? JSON.parse(creditsString) : { "GPT-3": 0, "GPT-4": 0 };
        return (credits["GPT-3"] || 0) + (credits["GPT-4"] || 0);
    };

    const [userCredits, setUserCredits] = useState(getCreditsSum());
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
    const [historyData, setHistoryData] = useState([]);
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const navigate = useNavigate();


    //const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 20;
    const [loading, setLoading] = useState(false);


    async function fetchUserHistory(currentPage) {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/user-history/${userId}/${currentPage}/${itemsPerPage}`);
        if (response.ok) {
            const newData = await response.json();
        
            console.log(newData);
            if (newData.length === 0) {
                Swal.fire({
                    title: t('history', 'attention'),
                    text: t('history', 'noHistory'),
                    icon: 'warning',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                })
            }

            if (newData.length < itemsPerPage) {
                setAllDataLoaded(true); // Todos os dados foram carregados
            }

            setHistoryData(prevData => [...prevData, ...newData]);

            if (currentPage === 1) {
                setIsLoadingInitialData(false); // Desativa o carregamento inicial após o primeiro carregamento de dados
            }

        } else {

            Swal.fire({
                title: t('history', 'errorTitle'),
                text: t('history', 'fetchHistoryError'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }

            })
        }
        setLoading(false);

    }

    useEffect(() => {

       /* if (bool) {
            bool = false;
            return; // Evita a execução do resto do useEffect na primeira renderização
        } */

        fetchUserHistory(prevPage);
    }, [userId]);


    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight + 5 && !loading) {
            prevPage += 1;
            fetchUserHistory(prevPage);
        }
    };

    return (
        <div className="history-screen">
            <ChakraProvider>
                <CustomDrawer
                    isOpen={isOpen}
                    onClose={handleCloseDrawer}
                    handleChange={handleChange}
                    onAnalysisOpen={() => analysisOpen(language)}
                    isGPT4Enabled={isGPT4Enabled}
                    onContentOpen={() => mainOpen(language)}
                    onPerfilOpen={() => perfilOpen(language)}
                    onPCI={()=>pciOpen(language)}
                    onLogout={() =>logout(language)}
                    t={t}
                    language = {language}
                    setLanguage = {setLanguage}
                    isCanvaConnected={localStorage.getItem('accessToken')}
                />
            </ChakraProvider>
            <div className="header_history" >
                <UserInfo
                    userName={userName}
                    userId={userId}
                    userCredits={userCredits}
                />
                <div className="image-container_history_01">
                    <MdMenu
                        className="menu-icon"
                        onClick={handleHamburguerClick}
                    />
                </div>
                <div className="image-container_history_02">
                    <img
                        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                        alt="Logo"
                        className="logo"
                    />
                </div>
            </div>

            <div className="divAreaImageHistory" >
                {isLoadingInitialData ? (
                    
                        <div className="spinner"></div>
                    
                ) : (
                       <h2 className="categoryTitleHistory">{t('history', 'creationHistory')}</h2> 
                )}
                
                <div className="images-grid" onScroll={handleScroll}>
                    {historyData.map((item) => {
                        if (!item.azureImageUrls || !item.azureImageUrls[0]) return null;
                        const coverImage = item.azureImageUrls[0].images.find(url => url.includes("image01"));
                        const sessionStartDate = new Date(item.sessionStart).toLocaleDateString('pt-BR');
                        return (
                            <a href={`https://docs.google.com/presentation/d/${item.presentationId}`} target="_blank" key={item._id}>
                                <div key={item._id} className="image-wrapper"
                                    onMouseOver={(e) => e.currentTarget.querySelector('.overlay').style.opacity = 0.5}
                                    onMouseOut={(e) => e.currentTarget.querySelector('.overlay').style.opacity = 0}
                                >
                                    <img src={coverImage} alt={'Capa do carrossel'} className="history-image" />
                                    <div className="overlay" style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'black',
                                        opacity: 0,
                                        transition: 'opacity 0.3s'
                                    }}></div>
                                    <div className="date-label" style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        color: 'white',
                                        fontSize: '12px'
                                    }}>
                                        {sessionStartDate}
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default HistoryScreen;
