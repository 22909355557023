import React from 'react';
import { useLocation } from 'react-router-dom';
import ChatbotBubble from '../utils/chatbase';

const ChatbotController = () => {
  const location = useLocation();

  return location.pathname === '/main' ? <ChatbotBubble /> : null;
};

export default ChatbotController;