import React, { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './LogoScreen.css';
import translations from '../utils/translations.json';

const LogoScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const queryParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.substring(1));

    // Autenticação do Facebook (mobile) e Canva (todos os dispositivos)
    const facebookToken = hashParams.get('access_token');
    const canvaCode = queryParams.get('code');
    const sessionId = queryParams.get('state');

    if (facebookToken && isMobile) {
      handleFacebookAuthentication(facebookToken);
    } else if (canvaCode) {

      //console.log("Recuperado - UserID:", userId);
      const parts = sessionId.split(':'); 
      const userId = parts[0];
      const language = parts[1];

      handleCanvaAuthentication(canvaCode, userId, language, isMobile);

    }
  }, [navigate]);

  

  const handleFacebookAuthentication = (facebookToken) => {
    const pageId = localStorage.getItem('pageId'); // Recuperar o pageId salvo anteriormente
    const userId = localStorage.getItem('userId'); // Recuperar o userId salvo anteriormente
    const totenIndex = localStorage.getItem('totenIndex'); // Recuperar o totenIndex salvo anteriormente
    const language = localStorage.getItem('language');
    const t = (screen, key) => translations[language][screen][key];


    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/convert-token`, {
      shortTermToken: facebookToken,
      pageId: pageId,
      userId: userId,
      totenIndex: totenIndex
    }).then(response => {
      const { data } = response;
      if (data && data.faceConection) {
        localStorage.setItem('tokenExpiry', data.tokenExpiry);
        navigate('/viewuser');
      }
    }).catch(error => {
      console.error('Erro ao conectar com o Instagram:', error);
      Swal.fire({
        title: t('logoScreen', 'instagramConnectionErrorTitle'),
        text: t('logoScreen', 'instagramConnectionErrorText'),
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    });
  };

  const handleCanvaAuthentication = (canvaCode, userId, language ,isMobile) => {

    const t = (screen, key) => translations[language][screen][key];

    console.log('DESCOBRINDO OS VALORES DAS VARIAVEIS: ');
    console.log(canvaCode);
    console.log(userId);

    fetch(`${process.env.REACT_APP_API_BASE_URL_PRODUCTION}/users/canvas-conexion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: canvaCode,
        userId: userId
      })
    })
      .then(response => {
        localStorage.removeItem('codeVerifier');// Limpar codeVerifier após uso
        console.log('DESCOBRINDO O ERRO DO SERVIDOR: ');
        console.log(response);
        Swal.fire({
          title: t('logoScreen', 'canvaConnectionSuccessTitle'),
          text: t('logoScreen', 'canvaConnectionSuccessText'),
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (!isMobile) { // Desktop
              window.opener && window.close();
            } else { // Mobile
              //navigate('/main');
              window.location.href = 'https://app.postsucesso.com.br/main';
            }
          }
        });
      }).catch(error => {
        console.error('Erro ao conectar com o Canva:', error);
        Swal.fire({
          title: t('logoScreen', 'canvaConnectionErrorTitle'),
          text: t('logoScreen', 'canvaConnectionErrorText'),
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (!isMobile) { // Desktop
              window.opener && window.close();
            } else { // Mobile
              navigate('/main');
            }
          }
        });
     });
  };

  return (
    <div className="logo-container-form-screen">
      <img
        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
        alt="Logo do PostSucesso"
        className="logo-image-form-screen"
      />
    </div>
  );
};

export default LogoScreen;
